#breadcrumbs {
    background-color: #e9ecef;
}

.alert.header-message {
    border-radius: 0 !important;
    text-align: center;
}

.dropdown-menu {
    top: -90px!important;
}

.embedded-video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    iframe {
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
#loader {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: rgba(0,0,0,0.75) url("/images/loading.gif") no-repeat center center;
    background-size: 56px 56px;
    z-index: 99998;
    text-align: center;
}
#loader p {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 120px;
    font-family: 'Times New Roman', 'Times', 'Baskerville', 'Georgia', serif;
    color: white;
    z-index: 99999;
}
